import { clsx } from 'clsx';
import { DropdownMenu } from 'components/UI/DropdownMenu';
import type { MenuItemProps, WidgetMenuProps } from './widget-menu-default';
import styles from './widget-menu-themenwelten.module.scss';

const WidgetMenuAccordion = ({ context }: { context: WidgetMenuProps }) => {
  return (
    <div>
      {context.title && <div className={styles.title}>{context.title}</div>}
      <ul className={styles.firstLevelList}>
        {context.model?.menu.map((firstLevel: MenuItemProps) => {
          return (
            <DropdownMenu
              key={`1-${firstLevel.title}`}
              menu={firstLevel}
              className={clsx(styles.firstLevel, firstLevel.fields?.initiallyOpen && styles.initiallyOpen)}
              level={1}
              backgroundColor="#55565B"
              linkClass="first-level"
              hideLogo
              initiallyOpen={firstLevel.fields?.initiallyOpen}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default WidgetMenuAccordion;
